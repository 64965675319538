import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import background from "./../images/home-consoles-preorder.jpg"
import logo from "./../images/logo-black-new.png"

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="flex items-center justify-center min-h-screen bg-fixed bg-center" style={{ backgroundImage: `url('${background}')` }}>
        <div className="px-6 md:px-0">
          <img style={{ maxWidth: "580px" }} className="w-full" src={logo} />
          <>
            <h1 className="mt-12 text-3xl text-center uppercase sm:text-5xl font-display">Page not found</h1>
          </>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
